<template>
  <div class="cus-container">
    <div
      class="cus-head"
      v-lazy:background-image="{src: require('../../../assets/images/lunbo6.png')}"
    >
      <div class="page-outter">
        <div align="center">
          <img
            class="cus-title"
            v-lazy="require('../../../assets/images/title18.png')"
          />
        </div>
        <div class="cus-d1">
          <div
            class="cus-d1-1"
            v-for="(item,index) in tds"
            :key="index"
          >
            <img
              class="m1"
              v-lazy="item.icon"
            />
            <span class="s1">{{item.title}}</span>
            <span class="s2">{{item.desc}}</span>
          </div>
        </div>
      </div>
      <div class="cus-d3">
        <!-- <img
          width="100%"
          height="100%"
          v-lazy="require('../../../assets/images/map3.png')"
        /> -->
      </div>
    </div>
    <!-- <div class="cus-d2"></div> -->
  </div>
</template>

<script>
export default {
  name: "contactUs",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon44.png"),
          title: "服务热线",
          desc: "400 860 1033",
        },
        {
          icon: require("../../../assets/images/app-icon45.png"),
          title: "公司地址",
          desc: "海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼",
        },
        {
          icon: require("../../../assets/images/app-icon46.png"),
          title: "联系邮箱",
          desc: "jiuyy@91medicine.cn",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.cus-container {
  position: relative;
  width: 100%;
}
.cus-head {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #fff;
  padding-bottom: 2.4rem;
}
.cus-title {
  width: 7rem;
  margin-top: 2.4rem;
  display: block;
}
.cus-d1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  .cus-d1-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .m1 {
      display: block;
      width: 1.6rem;
    }
    .s1 {
      display: block;
      font-size: 15px;
      color: #6aa5ff;
      text-align: center;
      margin-top: 20px;
    }
    .s2 {
      display: block;
      font-size: 13px;
      color: #dcdadd;
      text-align: center;
      margin-top: 10px;
    }
  }
}
.cus-d2 {
  width: 100%;
  height: 4px;
  background: #6aa5ff;
}
.cus-d3 {
  width: 61%;
  height: 420px;
  margin: 0 auto;
  margin-top: 50px;
}
</style>